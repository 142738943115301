var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.visible,"fullscreen":"","scrollable":""}},[_c('v-card',{style:(_vm.type === 'month' ? _vm.heightMonth : _vm.heightDay)},[_c('v-card-text',[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[(_vm.$refs.calendar)?_c('v-toolbar-title',[_c('a',{staticClass:"black--text",on:{"click":function($event){$event.preventDefault();_vm.art = 'month'}}},[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")])]):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"grey darken-2"},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1)]}}])},[_c('span',[_vm._v("Zurück")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1 mr-5",attrs:{"icon":"","color":"grey darken-2"},on:{"click":function($event){return _vm.$refs.calendar.next()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)]}}])},[_c('span',[_vm._v("Vor")])]),(_vm.type === 'month')?_c('v-btn',{staticClass:"mr-4",staticStyle:{"width":"115px"},attrs:{"outlined":"","color":"grey darken-2"},on:{"click":function($event){_vm.tag = ''}}},[_vm._v(" Heute ")]):_vm._e()],1),(_vm.loaders.termine)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_c('div',{staticStyle:{"height":"4px"}}),_c('v-calendar',{ref:"calendar",style:(_vm.type === 'month' ? _vm.height : 'height: 80vh; margin-bottom: 50px'),attrs:{"locale":"de","color":"primary","type":_vm.art,"weekdays":_vm.weekday,"first-time":"8:00","interval-minutes":_vm.intervalMinutes,"interval-count":(1440 - 780) / _vm.intervalMinutes,"short-intervals":false,"events":_vm.termine,"event-ripple":false},on:{"change":_vm.getEvents,"mousedown:event":_vm.startDrag,"mouseup:event":_vm.endDrag,"click:day":_vm.onClickDate,"click:date":_vm.onClickDate},nativeOn:{"mouseleave":function($event){return _vm.cancelDrag.apply(null, arguments)}},scopedSlots:_vm._u([{key:"day",fn:function(ref){
var day = ref.day;
var date = ref.date;
return [(_vm.isDayGreen(date) === 'green' && day)?_c('div',{staticClass:"v-calendar-weekly__day_color isgreen"}):_vm._e(),(_vm.isDayGreen(date) === 'red' && day)?_c('div',{staticClass:"v-calendar-weekly__day_color isred"}):_vm._e()]}},{key:"event",fn:function(ref){
var event = ref.event;
return [_c('div',{staticStyle:{"padding-left":"5px"}},[_vm._v(" "+_vm._s(_vm.$d(event.start, 'HH:mm'))+" - "+_vm._s(_vm.$d(event.end, 'HH:mm'))+" ")])]}}]),model:{value:(_vm.tag),callback:function ($$v) {_vm.tag=$$v},expression:"tag"}}),(_vm.systemdaten.terminbuchung_text1)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$purify(_vm.systemdaten.terminbuchung_text1))}}):_vm._e()],1),_c('v-card-actions',{staticClass:"card-actions"},[_c('v-spacer'),(_vm.type === 'day')?_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){_vm.type = 'month'}}},[_vm._v(" Zurück ")]):_vm._e(),(_vm.type === 'month')?_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.setKalenderEnter(true)}}},[_vm._v(" Zurück ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }