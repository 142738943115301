/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
// Prüfen ob die Property im angegebenen Pfad reactive ist
import Vue from 'vue';
import Vuex, { Store } from 'vuex';

export const checkReactive = (state: any, path: any): void => {
    let owner = {};
    let pKey = '';
    let property = state;

    (path ?? '').split('.').forEach((p: any) => {
        owner = property;
        pKey = p;
        property = property[p];
    });

    const descriptor = Object.getOwnPropertyDescriptor(owner, pKey);
    if (!descriptor || !descriptor.get) {
        (Vue as any).util.defineReactive(owner, pKey, property);
    }
};

const isObject = (obj: any): boolean => obj != null && (typeof obj === 'object' || typeof obj === 'function');

export const checkReactiveRecursive = (obj: any): void => {
    if (isObject(obj)) {
        Object.keys(obj).forEach((k) => {
            checkReactive(obj, k);
            if (isObject((obj as any)[k])) {
                checkReactiveRecursive((obj as any)[k]);
            }
        });
    }
};

// Plugin um den eingesetzten State in einen Vuex Store in Reactive properties zu konvertieren
export const replaceStateReactiveCheckPlugin = <S>(vuexstore: Store<S>): void => {
    const origReplaceState = Vuex.Store.prototype.replaceState;
    vuexstore.replaceState = (state: S): void => {
        checkReactiveRecursive(state);
        origReplaceState.call(vuexstore, state);
    };
};
