import api from '@/services/Api';
import { ActionContext, Module } from 'vuex';
import { AxiosResponse } from 'axios';
import { Terminkalender, Termin, Termindauer, Terminzeit } from '@/types/Terminkalender';
import { RootState, TerminkalenderState } from '@/store/types';
import { DataResponse } from '@/types';
import Anforderung from '@/types/Anforderung';

export type LoadTerminkalenderData = DataResponse<Partial<Termin>[], {
    anforderungen: Partial<Anforderung>[],
}>;
export type SetTerminkalenderLoaders = { save?: boolean; delete?: boolean; termine?: boolean; print?: boolean; export?: boolean };
export type SetTerminkalenderFilter = { von: string; bis: string };

type Context = ActionContext<TerminkalenderState, RootState>;

export const terminkalender: Module<TerminkalenderState, RootState> = {
    namespaced: true,
    state: {
        day: '',
        type: 'week',
        termin: new Termin(),
        kalender: new Terminkalender(),
        kalenderEnter: true,
        termineDialog: false,
        dialog: false,
        searchDialog: false,
        loaders: {
            save: false,
            delete: false,
            termine: false,
            suche: false,
        },
        filter: null,
        terminAnmeldungSuccess: false,
        closed: false,
    },
    actions: {
        loadKalender({ commit, state: { filter } }: Context): void {
            commit('setLoaders', { termine: true });
            //commit('clearTermine');

            api.post('terminkalender/terminkalender/load', { filter })
                .then((response: AxiosResponse) => {
                    commit('setKalender', response.data);
                })
                .catch(console.error)
                .finally(() => commit('setLoaders', { termine: false }));
        },
        updateTermin({ commit, dispatch }: Context, payload: Termin): void {
            commit('setLoaders', { save: true });

            api.post('terminkalender/terminkalender/speichern', payload)
                .then(() => {
                    dispatch('loadKalender');
                    commit('setDialog', false);
                    commit('setTerminanmeldung', true);
                })
                .catch(console.error)
                .finally(() => {
                    commit('setLoaders', { save: false })
                });
        },
    },
    mutations: {
        setAllClosed(state: TerminkalenderState, payload: boolean): void {
            state.closed = payload;
            state.kalenderEnter = true;
            state.termineDialog = false;
            state.terminAnmeldungSuccess = false;
        },
        setKalenderEnter(state: TerminkalenderState, payload: boolean): void {
            state.kalenderEnter = payload;
        },
        setDay(state: TerminkalenderState, day: string): void {
            state.day = day;
        },
        setType(state: TerminkalenderState, type: string): void {
            state.type = type;
        },
        setTermin(state: TerminkalenderState, termine: Termin): void {
            state.termin = termine;
        },
        clearTermine(state: TerminkalenderState): void {
            state.kalender.termine = [];
        },
        setKalender(state: TerminkalenderState, { body, selections }: LoadTerminkalenderData): void {
            state.kalender = new Terminkalender();
            state.kalender.termine = body.map((data) => new Termin(data));
            state.kalender.anforderungen = selections.anforderungen.map((data) => new Anforderung(data));
        },
        setDialog(state: TerminkalenderState, visible: boolean): void {
            state.dialog = visible;
        },
        setTerminanmeldung(state: TerminkalenderState, payload: boolean): void {
            state.terminAnmeldungSuccess = payload;
        },
        setTermineDialog(state: TerminkalenderState, payload: boolean): void {
            state.termineDialog = payload;
        },
        setLoaders(state: TerminkalenderState, loaders: SetTerminkalenderLoaders): void {
            state.loaders = {
                ...state.loaders,
                ...loaders,
            };
        },
        setFilter(state: TerminkalenderState, filter: SetTerminkalenderFilter): void {
            state.filter = filter;
        },
    },
};
