
































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {mapMutations, mapState} from 'vuex';
import { AxiosResponse } from 'axios';
import api from '@/services/Api';
import { VForm } from '@/types/Components';
import {ValidationRules} from '@/store/types';
import {Termin} from '@/types/Terminkalender';
import Anforderung from '@/types/Anforderung';

@Component({
    components: {
    },
    computed: {
        ...mapState('globals', ['rules']),
        ...mapState('terminkalender', ['kalenderEnter', 'terminAnmeldungSuccess', 'closed']),
    },
    methods: {
        ...mapMutations('terminkalender', ['setKalenderEnter', 'setAllClosed']),
    },
})
export default class TerminkalenderEnter extends Vue {
    @Prop() visible!: boolean;
    @Prop() termin!: Termin;

    public $refs!: {
        form: VForm;
    };

    public anforderungen : Anforderung[] = [];

    public rules!: ValidationRules;
    public setKalenderEnter!: (payload : boolean) => void;
    public setAllClosed!: (payload : boolean) => void;

    public mounted() {
        api.post('terminkalender/anforderungen/load')
            .then((response: AxiosResponse) => {
                this.anforderungen = response.data.body;
            });
    }

    @Watch('termin.anforderung_id')
    public setTerminDauer(termin: any) {
        api.post('terminkalender/anforderungen/get', {anforderung_id: this.termin.anforderung_id})
            .then((response: AxiosResponse) => {
                this.termin.dauer = response.data.body.dauer;
                this.termin.anforderung_bezeichnung = response.data.body.bezeichnung;
            });

        this.termin.dauer = termin.dauer;
    }

    public onClickCloseKalenderEnter() {
        if (this.$refs.form.validate()) {
            this.setKalenderEnter(false);
        }
    }

    public onClickClose() {
        this.setAllClosed(true);
    }
}
