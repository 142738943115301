import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Terminkalender from '@/views/Terminkalender.vue';

const routes: RouteConfig[] = [
    {
        path: '/',
        component: Terminkalender,
        meta: {
            name: 'Terminkalender',
        },
    },
];

Vue.use(VueRouter);

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = to.meta?.name ? to.meta.name + ' - IVSweb-Termine' : 'IVSweb-Termine';
    next();
});

export default router;
