import moment from 'moment';
import Anforderung from '@/types/Anforderung';

export class Termin {
    public anmeldung_id: number | null = null;
    public nachname: string | null = null;
    public vorname: string | null = null;
    public titel: string | null = null;
    public titel_nach_name: string | null = null;
    public geburtsdatum: string | null = null;
    public geschlecht: string | null = null;
    public svnr: string | null = null;
    public strasse: string | null = null;
    public plz: string | null = null;
    public ort: string | null = null;
    public land: string | null = null;
    public email: string | null = null;
    public telefon: string | null = null;
    public anforderung_id: number | null = null;
    public anforderung_bezeichnung: string | null = null;
    public hinweis = '';
    public dsgvo_einverstaendnis: string | null = null;
    public dsgvo_bemerkung: string | null = null;
    public impfstelle_id: number | null = null;
    public status: string | null = 'A';

    public datum: string | null = null;
    public zeit: string | null = null;
    public dauer: string | null = null;

    constructor(data?: Partial<Termin>) {
        Object.assign(this, data);
    }
}

export class Termindauer {
    public dauer: string | null = null;

    constructor(data?: Partial<Termindauer>) {
        Object.assign(this, data);
    }
}

export class Terminzeit {
    public zeit: string | null = null;

    constructor(data?: Partial<Terminzeit>) {
        Object.assign(this, data);
    }
}

export interface TerminEvent {
    zeitStart?: string;
    zeitEnde?: string;
    start: number;
    end: number;
    timed: boolean;
    dauer?: any | null;
    termin_id?: number | null;
    erschienen?: string | null;

}

export class Terminkalender {
    public termine: Termin[] = [];
    public anforderungen: Anforderung[] = [];


   public get events(): TerminEvent[] {
        return this.termine.map((termin: Termin) => {

            const [zeitStunden, zeitMinuten] = termin.zeit?.split(':') ?? [];
            const [dauerStunden, dauerMinuten] = termin.dauer?.split(':') ?? [];

            const zeitStart = moment(termin.datum)
                .set('hour', parseInt(zeitStunden, 10))
                .set('minute', parseInt(zeitMinuten, 10));
            const zeitEnde = zeitStart.clone()
                .add(dauerStunden, 'hours')
                .add(dauerMinuten, 'minutes');


            return {
                zeitStart: zeitStart.format('HH:mm'),
                zeitEnde: zeitEnde.format('HH:mm'),
                start: zeitStart.unix() * 1000,
                end: zeitEnde.unix() * 1000,
                timed: true,
                anforderung_id: termin.anmeldung_id,
                dauer: termin.dauer
            };
        });
    }
}
