import swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';

export function successSwal(title: string, text?: string): void {
    swal.fire({
        icon: 'success',
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000,
        position: 'top-start',
        toast: true,
    });
}

export function toastSwal(icon?: SweetAlertIcon, title?: string, text?: string, timer?: number): void {
    swal.fire({
        icon,
        title,
        text: text,
        showConfirmButton: false,
        timer: timer ?? 3000,
        position: 'top-start',
        toast: true,
    });
}

export function questionSwal(title: string, question?: string, confimtext?: string, canceltext?: string) :
    Promise<SweetAlertResult> {
    return swal.fire({
        icon: 'question',
        title: title,
        text: question,
        showCancelButton: true,
        confirmButtonText: confimtext,
        cancelButtonText: canceltext,
        customClass: {
            confirmButton: 'v-btn v-btn--depressed theme--light v-size--small primary',
            cancelButton: 'v-btn v-btn--depressed theme--light v-size--small grey',
        },
    });
}

export function confirmSwal(title: string, text?: string, confimtext?: string, canceltext?: string) :
    Promise<SweetAlertResult> {
    return swal.fire({
        icon: 'warning',
        title: title,
        text: text,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: confimtext || 'Ja',
        cancelButtonText: canceltext || 'Nein',
        customClass: {
            confirmButton: 'v-btn v-btn--depressed theme--light v-size--small primary',
            cancelButton: 'v-btn v-btn--depressed theme--light v-size--small grey',
        },
    });
}

export function errorSwal(title: string, text?: string, confimtext?: string): void {
    swal.fire({
        icon: 'error',
        title: title,
        text: text,
        showConfirmButton: true,
        confirmButtonText: confimtext || 'Ok',
        customClass: {
            confirmButton: 'v-btn v-btn--depressed theme--light v-size--small primary',
        },
        width: 'auto',
    });
}

export function warningSwal(title: string, text?: string, confimtext?: string): void {
    swal.fire({
        icon: 'warning',
        title: title,
        text: text,
        showConfirmButton: true,
        confirmButtonText: confimtext || 'Ok',
        customClass: {
            confirmButton: 'v-btn v-btn--depressed theme--light v-size--small primary',
        },
    });
}

export function dirtySwal(): Promise<SweetAlertResult> {
    return swal.fire({
        icon: 'warning',
        title: 'Sind Sie sicher?',
        html: 'Sie haben auf dieser Seite Änderungen vorgenommen, die nicht gespeichert wurden.' +
            ' Wenn Sie die Seite verlassen, werden Sie diese Änderungen verlieren.',
        confirmButtonText: 'Bleiben',
        cancelButtonText: 'Verlassen',
        showCancelButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        focusCancel: true,
        backdrop: true,
        customClass: {
            confirmButton: 'v-btn v-btn--depressed theme--light v-size--small primary',
            cancelButton: 'v-btn v-btn--depressed theme--light v-size--small grey',
        },
    });
}
