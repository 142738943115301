





























import { mapActions, mapMutations, mapState } from 'vuex';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { base } from '@/services/Api';
import { confirmSwal } from '@/helper/swals';

@Component({
    components: {
    },
    computed: {
        ...mapState('application', ['version', 'initialized']),
    },
    methods: {
        ...mapActions('application', ['startWorker', 'stopWorker', 'rerollVersion']),
        ...mapActions('globals', ['loadTitle']),
        ...mapMutations('application', { initApplication: 'initialized' }),
    },
})
export default class App extends Vue {
    public initialized!: boolean;
    public initApplication!: (value: boolean) => void;
    public startWorker!: () => void;
    public stopWorker!: () => void;
    public rerollVersion!: () => void;

    public rerollVersionDone = false;

    public mounted(): void {
        //this.startWorker();
        this.initApplication(true);
    }

    public get appVersion(): string | undefined {
        return process.env.VUE_APP_VERSION;
    }

    public get devprod(): string | undefined {
        return process.env.VUE_APP_DEVPROD;
    }

    public get testsystem(): string {
        return '';
    }

    public goto(route: string): void {
        if (this.$router.currentRoute.path !== route) {
            this.$router.push(route);
        }
    }

    public onClickHome(): void {
        if (!this.initialized) {
            return;
        }

        if (this.$router.currentRoute.path !== '/') {
            this.$router.push('/');
        }
    }

    public created(): void {
        // https://github.com/vuejs/vue-router/issues/1849
        if ('-ms-scroll-limit' in document.documentElement.style &&
            '-ms-ime-align' in document.documentElement.style) {
            window.addEventListener('hashchange', () => {
                const currentPath = window.location.hash.slice(1);
                if (this.$route.path !== currentPath) {
                    this.$router.push(currentPath);
                }
            }, false);
        }
    }

    public openTab(route: string): void {
        window.open(base + route, '_blank');
    }

    @Watch('version')
    public onChangeVersion(): void {
        if (this.rerollVersionDone) {
            return;
        }

        confirmSwal(
            'Neue Version verfügbar',
            'Soll die neue Version geladen werden? Ungespeicherte Daten gehen dabei verloren.',
        ).then((res) => {
            if (res.value) {
                document.location.reload();
            } else {
                this.rerollVersionDone = true;
                this.rerollVersion();
            }
        });
    }
}
