export default class Systemdaten {
    /** PK: SystemdatenRow | erforderlich **/
    public id: number | null = null;
    /**  Text nach Anrede in Befunden | max. 255 Zeichen **/
    public befundtext1: string | null = null;
    /**  Schlusstext in Befunden | max. 255 Zeichen **/
    public befundtext2: string | null = null;
    /** Ort | max. 50 Zeichen **/
    public ort: string | null = null;
    /** Umsatzsteuer **/
    public ust: number | null = null;
    /** Mail Server | max. 100 Zeichen **/
    public mail_server: string | null = null;
    /** Benutzername für Mail Server | max. 100 Zeichen **/
    public mail_username: string | null = null;
    /**  Passwort für Mail Server | max. 100 Zeichen **/
    public mail_password: string | null = null;
    /** Mail Sender Name | max. 100 Zeichen **/
    public mail_sender: string | null = null;
    /** ELGA-Anbindung aktiv j/n | max. 1 Zeichen **/
    public elga_anbindung_aktiv: string | null = null;
    /** Verzeichnis für Export an Registrierkasse (z.B. cbird) | max. 255 Zeichen **/
    public rk_verzeichnis: string | null = null;
    /** Text in Terminübersicht | max. 255 Zeichen **/
    public terminbuchung_text1: string | null = null;
    /** Text in Anmeldungsformular | max. 255 Zeichen **/
    public terminbuchung_text2: string | null = null;
    /** Text in Abschluss | max. 255 Zeichen **/
    public terminbuchung_text3: string | null = null;

    constructor(data?: Partial<Systemdaten>) {
        Object.assign(this, data);
    }
}
