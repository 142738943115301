import Vue from 'vue';
import Vuex from 'vuex';
import { RootState } from '@/store/types';
import { application } from '@/store/application';
import { globals } from '@/store/globals';
import { terminkalender } from '@/store/terminkalender';
import { replaceStateReactiveCheckPlugin } from '@/store/reactiveCheck';

Vue.use(Vuex);

const store = new Vuex.Store<RootState>({
    modules: {
        application,
        globals,
        terminkalender,
    },
    plugins: [
        replaceStateReactiveCheckPlugin,
    ],
});

export default store;
