























import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapMutations, mapState } from 'vuex';
import TerminkalenderEnter from '@/components/TerminkalenderEnter.vue';
import TerminkalenderDetail from '@/components/TerminkalenderDetail.vue';
import TerminkalenderView from '@/components/TerminkalenderView.vue';
import { Termin } from '@/types/Terminkalender';
import TerminkalenderAnmeldungSuccess from '@/components/TerminkalenderAnmeldungSuccess.vue';

@Component({
    components: {
        TerminkalenderAnmeldungSuccess,
        TerminkalenderEnter,
        TerminkalenderDetail,
        TerminkalenderView
    },
    computed: {
        ...mapState('terminkalender', ['dialog', 'termin', 'loaders', 'kalenderEnter', 'terminAnmeldungSuccess', 'termineDialog', 'closed']),
    },
    methods: {
        ...mapMutations('terminkalender', ['setDialog', 'setTermineDialog']),
    },
})
export default class Terminkalender extends Vue {
    public setDialog!: (visible: boolean) => void;
    protected setTermineDialog: any;

    public terminNew = new Termin();

    public mounted() {
        (window as any).terminkalender = this;
    }

    public onCloseDetail(): void {
        this.setDialog(false);
    }
}
