import moment from 'moment';
import { ActionContext, Module } from 'vuex';
import { GlobalsState, RootState } from '@/store/types';
import { Geschlecht } from '@/types/Geschlecht';
import { InputValidationRules } from 'vuetify';
import Titel from '@/types/Titel';
import api from '@/services/Api';

type Context = ActionContext<GlobalsState, RootState>;

export const globals: Module<GlobalsState, RootState> = {
    namespaced: true,
    state: {
        rules: {
            required: (value: unknown): boolean | string => {
                return (value !== null &&
                    value !== undefined &&
                    (typeof value === 'string' ? value.trim() !== '' : true) &&
                    (Array.isArray(value) ? value.length > 0 : true)) || 'Erforderlich.';
            },
            maxLength: (length: number) => {
                return (value: string | null): boolean | string => {
                    return ((value?.toString()?.length || 0) <= length) || `Maximal ${length} Buchstaben erlaubt.`;
                };
            },
            maxNumberLength: (length: number) => {
                return (value: string | null): boolean | string => {
                    return ((value?.toString()?.length || 0) <= length) || `Maximal ${length} Ziffern erlaubt.`;
                };
            },
            onlyNumbers: (value: string | null): boolean | string => {
                return /^(\d{0,})$/.test(value?.toString() || '0') || 'Es dürfen nur Zahlen eingeben werden.';
            },
            onlyNumbersAllowNegative: (value: string | null): boolean | string => {
                return /^(-?\d{0,})$/.test(value?.toString() || '0') || 'Es dürfen nur Zahlen eingeben werden.';
            },
            numeric: (precision: number, scale: number) => {
                return (value: string | null): boolean | string => {
                    value = (value?.toString() ?? '0').replaceAll('.', '');
                    return new RegExp(`^[-]?[0-9]{0,${precision - scale}}[,]?[0-9]{0,${scale}}$`).test(value) ||
                        `Wert muss numerisch sein z.B. 1,${'9'.padStart(scale, '9')}`;
                };
            },
            isNumber: (value: string | null): boolean | string => {
                return /^(\d{0}|[0-9]{4})$/.test(value?.toString() || '0') ||
                    'Es müssen vier Zahlen eingegeben werden.';
            },
            minLength: (length: number) => {
                return (value: string | null): boolean | string => {
                    return ((value?.toString()?.length || 0) >= length) || `Minimum ${length} Buchstaben erforderlich.`;
                };
            },
            minNumberLength: (length: number) => {
                return (value: string | null): boolean | string => {
                    return ((value?.toString()?.length || 0) >= length) || `Minimum ${length} Zahlen erforderlich.`;
                };
            },
            exactDigits: (length: number) => {
                return (value: string | null): boolean | string => {
                    return ((value?.toString()?.length || 0) >= length) || `Es sind ${length} Ziffern erforderlich.`;
                };
            },
            validGender: (genders: Geschlecht[]) => {
                return (value: string | null): boolean | string => {
                    return (genders.find((geschlecht) => geschlecht === value) !== undefined) || 'Erforderlich.';
                };
            },
            validSvnr: (value: string | null): boolean | string => {
                if (value?.length === 10) {
                    const svnr = value.split('').map((zahl: string) => parseInt(zahl, 10));

                    const rest = [[0, 3], [1, 7], [2, 9], [4, 5], [5, 8], [6, 4], [7, 2], [8, 1], [9, 6]]
                        .map(([index, multiplier]: number[]) => svnr[index] * multiplier)
                        .reduce((acc: number, val: number) => acc += val, 0) % 11;

                    if (rest === svnr[3]) {
                        return true;
                    }
                }

                return 'Ungültige Sozialversicherungsnummer.';
            },
            validMail: (value: string): boolean | string => {
                return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) ||
                    'Ungültige E-mail.';
            },
            validTelefonnummer: (value: string): boolean | string => {
                return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(value) ||
                    'Ungültige Telefonnummer';
            },
            validPrice: (value: string): boolean | string => {
                return /^(\d{0}| | \d+(?:[.,]\d{1,3})?)$/.test(value) ||
                    'Preise müssen nach dem Komma mindestens eine Stelle besitzen.';
            },
            validDate: (date: string | null): boolean | string => {
                return moment(date, ['YYYY-MM-DD'], true).isValid() || 'Datum entspricht nicht dem erlaubten Format.';
            },
            validTime: (time: string | null): boolean | string => {
                return moment(time, ['HH:mm'], true).isValid() || 'Zeit entspricht nicht dem erlaubten Format.';
            },
            fromValidator(validator: Record<string, unknown>): InputValidationRules {
                return Object.entries(validator ?? {})
                    .map(([validationType, validationValue]) => {
                        switch (validationType) {
                            case 'maxlen':
                                return this.maxLength(validationValue as number);
                            case 'required':
                                return this.required;
                            default:
                                return null;
                        }
                    })
                    .filter((rule) => rule !== null) as InputValidationRules;
            },
        },
        formatDate(input: string | null): string | null {
            if (!input) {
                return '';
            }

            const date = moment(input.split(' ')[0], ['YYYY-MM-DD'], true);
            return date.isValid() ? date.format('DD.MM.YYYY') : input;
        },
        parseDate(input: string | null): string | null {
            if (!input) {
                return '';
            }

            const date = moment(input.split(' ')[0], ['DD.MM.YYYY'], true);
            return date.isValid() ? date.format('YYYY-MM-DD') : input;
        },
        formatTime(input: string | null): string | null {
            if (!input) {
                return '';
            }

            const date = moment(input.slice(0, 16), ['YYYY-MM-DD HH:mm'], true);
            return date.isValid() ? date.format('HH:mm') : input;
        },
        parseTime(input: string | null): string | null {
            if (!input) {
                return '';
            }

            const date = moment(input.slice(0, 16), ['DD.MM.YYYY HH:MM'], true);
            return date.isValid() ? date.format('HH:mm:ss') : input;
        },
        sortDate<T>(a: T, b: T): number {
            if (a === b) {
                return 0;
            } else if (!a) {
                return 1;
            } else if (!b) {
                return -1;
            } else {
                const d1 = moment(b, ['DD.MM.YYYY']).unix();
                const d2 = moment(a, ['DD.MM.YYYY']).unix();
                return d1 === d2 ? 0 : d1 < d2 ? 1 : -1;
            }
        },
        titelauswahl: [],
    },
    actions: {
        loadTitle({ commit }: Context): void {
            api.get('verwaltung/titel/load')
                .then((response) => commit('setTitle', response.data.body))
                .catch(console.error);
        },
    },
    mutations: {
        setTitle(state: GlobalsState, title: Titel[]): void {
            state.titelauswahl = title;
        },
    },
};
