






























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {mapMutations, mapState} from 'vuex';
import {Termin} from '@/types/Terminkalender';
import api from '@/services/Api';
import Systemdaten from '@/types/Systemdaten';

@Component({
    components: {
    },
    computed: {
        ...mapState('globals', ['rules']),
        ...mapState('terminkalender', ['kalenderEnter', 'terminAnmeldungSuccess', 'closed']),
    },
    methods: {
        ...mapMutations('terminkalender', ['setKalenderEnter', 'setAllClosed']),
    },
})
export default class TerminkalenderAnmeldungSuccess extends Vue {
    @Prop() visible!: boolean;
    public systemdaten: Systemdaten = new Systemdaten();

    public setAllClosed!: (payload : boolean) => void;

    public onClickClose() {
        this.setAllClosed(true);
        if (/buchung\.html/.test(window.location.pathname)) {
            window.close();
        }
    }

    @Watch('visible')
    public onChangeVisible(visible: boolean): void {
        api.post('terminkalender/terminkalender/loadsystemdaten')
            .then((response: any) => {
                this.systemdaten = response.data.body;
            });
    }
}
