





























































































































































import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapMutations, mapState } from 'vuex';
import moment from 'moment';
import { CalendarEventParsed, CalendarTimestamp } from 'vuetify';
import { ValidationRules } from '@/store/types';
import {
    Termin,
    TerminEvent,
    Terminkalender as TerminkalenderType,
} from '@/types/Terminkalender';
import { SetTerminkalenderFilter } from '@/store/terminkalender';
import $ from 'jquery';
import _ from 'lodash';
import api from '@/services/Api';
import Systemdaten from '@/types/Systemdaten';

@Component({
    components: {
    },
    computed: {
        ...mapState('terminkalender', ['day', 'termin', 'kalender', 'dialog', 'loaders']),
    },
    methods: {
        ...mapActions('terminkalender', ['loadKalender', 'updateTermin', 'deleteTermin']),
        ...mapMutations('terminkalender', [
            'setDay',
            'setType',
            'setDialog',
            'setFilter',
            'setTermin',
            'setKalenderEnter'
        ]),
    },
})
export default class Terminkalender extends Vue {
    @Prop() visible!: boolean;
    @Prop() value!: Termin;
    public $refs!: {
        calendar: Vue;
    };

    public systemdaten: Systemdaten = new Systemdaten();

    public heightDay = 'height: 960px';
    public heightMonth = 'height: 555px';
    public height = 'height: 390px';
    public weekday = [1, 2, 3, 4, 5, 6];
    public intervalHeight = 20;
    public intervalMinutes = 15;
    public slotsCount = 0;

    public day!: string;
    public type = 'month';
    public setDay!: (day: string) => void;
    public rules!: ValidationRules;
    public kalender!: TerminkalenderType;
    public loadKalender!: () => void;
    public dialog!: boolean;
    public setTermin!: (termin: Termin) => void;
    public termin!: Termin;
    public setDialog!: (visible: boolean) => void;
    public setFilter!: (filter: SetTerminkalenderFilter) => void;
    public setType!: (type: string) => void;
    public setKalenderEnter!: (payload : boolean) => void;

    public events: TerminEvent[] = [];
    public dragEvent: TerminEvent | null = null;
    public createEvent: TerminEvent | null = null;
    public createStart: number | null = null;
    public extendOriginal: number | null = null;
    public dragTime: number | null = null;

    public isDayGreen(value:any) {
        var today = moment().format('YYYY-MM-DD');
        if (value >= today) {
            var elements = document.querySelectorAll(`[data-date = "${value}"]`);
            if (elements.length > 0) {
                return 'green';
            } else {
                return 'red';
            }
        }
    }

    @Watch('termine')
    public disableDays() {
        $('.v-past').addClass('v-outside');
        $('.v-past').children().children().attr('disabled', 'true');
        $('.v-past').children().children().attr('pointer-events', 'none');
    }

    public get tag(): string {
        return this.day;
    }

    public set tag(tag: string) {
        this.setDay(tag);
    }

    public get art(): string {
        return this.type;
    }

    public set art(art: string) {
        this.setType(art);
    }

    @Watch('visible')
    public onChangeVisible(visible: boolean): void {
        if (visible) {
            if (this.value.dauer) {
                var time = this.value.dauer;
                var array = time.split(':');
                var seconds = ((parseInt(array[0], 10) * 60 * 60) + (parseInt(array[1], 10) * 60)) / 60;
                this.intervalMinutes = seconds;
            }

            api.post('terminkalender/terminkalender/loadsystemdaten')
                .then((response: any) => {
                    this.systemdaten = response.data.body;
                });
        }
    }

    public get termine(): TerminEvent[] {
        if (this.events.length === 0) {
            this.events = [...this.kalender.events ?? []];
        }

        const retval :any [] = [];

        _.forEach(this.events, (event: TerminEvent) => {
            var dauer = event.dauer;
            var array = dauer.split(':');
            var seconds = ((parseInt(array[0], 10) * 60 * 60) + (parseInt(array[1], 10) * 60)) / 60;

            var newItem: TerminEvent;

            while (seconds >= this.intervalMinutes) {
                newItem = {
                    start: event.start,
                    end: event.start + (this.intervalMinutes * 60 * 1000),
                    timed: true,
                };

                retval.push(newItem);

                seconds = seconds - this.intervalMinutes;
                event.start = newItem.end;
            }
        });

        return retval;
    }

    @Watch('intervalMinutes')
    public onChangeintervalMinutes(): void {
        this.slotsCount = (1440 - 780) / this.intervalMinutes;
    }

    @Watch('loaders.termine')
    public onChangeLoadersTermine(isLoading: boolean): void {
        if (!isLoading && this.type !== 'month') {
            this.$nextTick(() => {
                const [termin] = this.termine.sort((a, b) => (a.zeitStart ?? '').localeCompare(b.zeitStart ?? ''));
                const scrollArea = this.$refs.calendar.$el.querySelector('.v-calendar-daily__scroll-area');

                if (termin && scrollArea) {
                    const start = moment(termin.start);
                    const origin = start.clone().set({ hour: 8, minute: 0, second: 0});
                    const heightPerMinute = this.intervalHeight / this.intervalMinutes;
                    scrollArea.scrollTop = start.diff(origin, 'minutes') * heightPerMinute;
                }
            });
        }
    }

    public startDrag({ event, timed }: { event: TerminEvent; timed: boolean }): void {
        if (event && timed) {
            this.dragEvent = event;
            this.dragTime = null;
            this.extendOriginal = null;
        }
    }

    @Watch('kalender.events')
    public onChangeKalenderEvents(events: TerminEvent[]): void {
        this.events = events;
    }

    public endDrag({ date, event }: {date: CalendarTimestamp; event : TerminEvent }): void {
        setTimeout(() => {
            const event = this.createEvent ?? this.dragEvent;
            if (!event) return;

            const termin = this.createEvent !== null && this.extendOriginal === null
                ? new Termin()
                : new Termin({
                    ...this.kalender.termine.find((termin: Termin) => {
                        return termin.anmeldung_id === event.termin_id;
                    }),
                });

            const eventEnd = moment(event.end, 'x');
            const eventStart = moment(event.start, 'x');
            const diffHours = eventEnd.diff(eventStart, 'hours');
            const diffMinutes = eventEnd.diff(eventStart, 'minutes');

            const padTime = (time: number): string => time.toString().padStart(2, '0');

            termin.zeit = padTime(eventStart.hour()) + ':' + padTime(eventStart.minute());
            termin.dauer = padTime(diffHours) + ':' + padTime(diffMinutes % 60);
            termin.datum = moment(event.start).format('YYYY-MM-DD');

            this.setTermin(termin);
            this.setDialog(true);

            this.dragTime = null;
            this.dragEvent = null;
            this.createEvent = null;
            this.createStart = null;
            this.extendOriginal = null;
        }, 2);
    }

    public cancelDrag(): void {
        if (this.createEvent) {
            if (this.extendOriginal) {
                this.createEvent.end = this.extendOriginal;
            } else {
                const i = this.events.indexOf(this.createEvent);
                if (i !== -1) {
                    this.events.splice(i, 1);
                }
            }
        }

        this.createEvent = null;
        this.createStart = null;
        this.dragTime = null;
        this.dragEvent = null;
    }

    public roundTime(time: number, down = true): number {
        const roundTo = this.intervalMinutes;
        const roundDownTime = roundTo * 60 * 1000;

        return down
            ? time - time % roundDownTime
            : time + (roundDownTime - (time % roundDownTime));
    }

    public toTime(tms: CalendarTimestamp): number {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
    }

    public getEvents({ start, end }: CalendarEventParsed): void {
        this.setFilter({
            von: start.date,
            bis: this.type === 'day' ? start.date : end.date,
        });
        this.loadKalender();
    }

    public onClickDate(data:any): void {
        this.setDay(data.date);
        this.type = 'day';
        this.art = 'week';
    }
}
