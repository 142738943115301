



















































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';
import { Termin } from '@/types/Terminkalender';
import { warningSwal} from '@/helper/swals';
import { ValidationRules } from '@/store/types';
import { VForm, VTextField, VAutocomplete} from '@/types/Components';
import api from '../services/Api';
import Impfstelle from '@/types/Impfstelle';
import Systemdaten from '@/types/Systemdaten';

@Component({
    components: {

    },

    computed: {
        ...mapState('globals', ['rules', 'titelauswahl', 'formatDate', 'parseDate']),
        ...mapState('terminkalender', ['kalender', 'loaders']),
    },
    methods: {
        ...mapActions('terminkalender', ['updateTermin', 'deleteTermin']),
        ...mapActions('globals', ['loadTitle']),
        ...mapActions('patient', ['loadPatient']),
    },
})
export default class TerminkalenderDetail extends Vue {
    @Prop() value!: Termin | null;
    @Prop() terminNew!: Termin | null;
    @Prop() visible!: boolean;

    public $refs!: {
        form: VForm;
        svnrLaufnummer: VTextField;
        svnrGeburtsdatum: VTextField;
        geburtsdatum: VTextField;
        email: VAutocomplete;
    };

    public impfstelle:Impfstelle | any = [];
    public systemdaten: Systemdaten = new Systemdaten();

    public rules!: ValidationRules;
    public parseDate!: (input: string | null) => string | null;
    public formatDate!: (input: string | null) => string | null;
    public geburtsdatumMenu = false;
    public geburtsdatumFormatted: string | null = null;
    public geburtsdatumErrorMessages: string[] = [];
    public termin = new Termin();
    public kalender:any;
    public svnrLaufnummer = '';
    public svnrGeburtsdatum = '';
    public svnrErrorMessages: string[] = [];
    public updateTermin!: (termin: Termin) => void;
    public loadTitle!: () => void;

    public mounted() {
        this.loadTitle();
    }

    public get getAnforderungtext() {
        if (this.termin.anforderung_bezeichnung) return this.termin.anforderung_bezeichnung + ' ' + this.termin.hinweis;
        else return '';
    }

    public get isNew(): boolean {
        return this.termin.anmeldung_id === null;
    }

    @Watch('termin.geburtsdatum')
    public onChangeGeburtsdatum(date: string | null): void {
        this.geburtsdatumFormatted = this.formatDate(this.termin.geburtsdatum);

        if (date !== null) {
            this.validateGeburtsdatum();
        }

        if (this.geburtsdatumErrorMessages.length === 0 &&
            this.geburtsdatumFormatted) {
            this.svnrGeburtsdatum = [0, 3, 8]
                .map((index) => this.geburtsdatumFormatted?.substr(index, 2))
                .join('');

            if (this.svnrLaufnummer) {
                this.validateSvnr();
            }
        }
    }

    public onInputSvnrLaufnummer(value: string): void {
        if (!this.$refs.svnrGeburtsdatum) {
            return;
        }

        if (value.length === 10) {
            this.svnrGeburtsdatum = value.slice(4);
            this.$refs.svnrGeburtsdatum.focus();
        } else if (value.length === 5 &&
            (this.$refs.svnrLaufnummer.$refs.input as HTMLInputElement).selectionStart === 5) {
            this.svnrGeburtsdatum = value.slice(4);
            this.$refs.svnrGeburtsdatum.focus();
        }
    }

    public validateGeburtsdatum(): void {
        this.geburtsdatumErrorMessages = [];

        for (const rule of [this.rules.required, this.rules.validDate]) {
            const valid = rule(this.termin.geburtsdatum);
            if (valid !== true) {
                this.geburtsdatumErrorMessages.push(valid as string);
            }
        }
    }

    public onClickAutoFillSvnr(): void {
        if (this.svnrLaufnummer.length !== 4) {
            this.svnrLaufnummer = '0000';
        }

        if (this.svnrGeburtsdatum.length !== 6) {
            if (this.geburtsdatumErrorMessages.length === 0 &&
                this.geburtsdatumFormatted &&
                !this.svnrGeburtsdatum) {
                this.svnrGeburtsdatum = [0, 3, 8]
                    .map((index) => this.geburtsdatumFormatted?.substr(index, 2))
                    .join('');
            } else {
                this.svnrGeburtsdatum = '000000';
            }
        }

        this.validateSvnr();
    }

    public validateSvnr(): void {
        this.svnrErrorMessages = [];

        if (this.$refs.svnrLaufnummer?.isFocused ?? true) return;
        if (this.$refs.svnrGeburtsdatum?.isFocused ?? true) return;

        const rules = [
            this.rules.required,
            this.rules.exactDigits(10),
            /*this.rules.validSvnr,*/
        ];

        for (const rule of rules) {
            const valid = rule(this.svnr);
            if (valid !== true) {
                this.svnrErrorMessages.push(valid as string);
            }
        }

        this.termin.svnr = this.svnr;
    }

    public get svnr(): string {
        return this.svnrLaufnummer + this.svnrGeburtsdatum;
    }

    @Watch('visible')
    public onChangeVisible(visible: boolean): void {
        if (visible) {
            if (this.value) {
                this.termin = new Termin(this.value);
            } else {
                this.termin = new Termin();
            }

            if (this.terminNew) {
                this.termin.hinweis = this.terminNew.hinweis;
                this.termin.anforderung_id = this.terminNew.anforderung_id;
                this.termin.anforderung_bezeichnung = this.terminNew.anforderung_bezeichnung;
            }

            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }

            api.post('terminkalender/terminkalender/loadimpfstelle')
                .then((response: any) => {
                    this.impfstelle = response.data.body;
                });

            api.post('terminkalender/terminkalender/loadsystemdaten')
                .then((response: any) => {
                    this.systemdaten = response.data.body;
                });
        }
    }

    public onKeydownTab(ev: KeyboardEvent, source: string): void {
        const refs = this.$refs;

        switch (source) {
            case 'svnrLaufnummer':
            case 'svnrGeburtsdatum': {
                refs.email.focus();
                break;
            }
            default:
                break;
        }
    }

    public onKeydownRight(ev: KeyboardEvent): void {
        if (((ev.target as HTMLInputElement)?.selectionStart ?? 0) >= 4) {
            ev.preventDefault();
            this.$refs.svnrGeburtsdatum.focus();
        }
    }

    public onKeydownLeft(ev: KeyboardEvent): void {
        if ((ev.target as HTMLInputElement).selectionStart === 0) {
            ev.preventDefault();
            this.$refs.svnrLaufnummer.focus();
        }
    }

    public onClickSave(): void {
        this.validateGeburtsdatum();
        this.validateSvnr();

        if (this.$refs.form.validate() &&
            this.geburtsdatumErrorMessages.length === 0 &&
            this.svnrErrorMessages.length === 0) {
            this.updateTermin(new Termin({
                ...this.termin
            }));
        } else {
            warningSwal('Terminbuchung', 'Bitte füllen Sie alle Pflichtfelder aus bzw. akzeptieren Sie die Datenschutzerklärung.');
        }
    }
}
