import { AxiosError, AxiosResponse } from 'axios';

export interface ResponseFlags {
    readonly?: boolean;
    readonlyUpdateFields?: string[];
}

export interface NotificationInterface {
    class: any;
    message: any;
}

export interface ResponseInterface<T> {
    class: any;
    code: any;
    body?: T;
    notifications: NotificationInterface[];
    permissions: any[];
    selections: any[];
    flags: ResponseFlags;

    data(): T;

    warningMessages(): string[];

    errorMessages(): string[];

    successMessages(): string[];
}

class ResponseFactory {
    public factory<T>(response: AxiosResponse<ResponseInterface<T>> | AxiosError): AbstractResponse<T> {
        if (!response) {
            return new DataResponse<T>({});
        }
        if ('response' in response) {
            response = response.response as AxiosResponse<ResponseInterface<T>>;
            return new ErrorResponse<T>(response.data);
        }

        response = response as AxiosResponse<ResponseInterface<T>>;

        if (!response.data.class) {
            return new DataResponse<T>(response.data);
        }

        switch (response.data.class) {
            case 'DataResponse': {
                return new DataResponse<T>(response.data);
            }

            case 'SelectionResponse': {
                return new SelectionResponse<T>(response.data);
            }

            case 'ExtGridResponse': {
                return new ExtGridResponse<T>(response.data);
            }

            default: {
                return new DataResponse<T>(response.data);
            }
        }
    }
}

export class AbstractResponse<T> implements ResponseInterface<T> {
    public class = 'DataResponse';
    public code = 200;
    public body = undefined;
    public notifications: any[] = [];
    public permissions = [];
    public selections = [];
    public flags: ResponseFlags = {};

    constructor(data: any) {
        if (data.body !== undefined) {
            this.body = data.body;
        }
        if (data.code) {
            this.code = data.code;
        }
        if (data.notifications) {
            this.notifications = data.notifications;
        }
        if (data.permissions) {
            this.permissions = data.permissions;
        }
        if (data.selections) {
            this.selections = data.selections;
        }

        if (data.flags) {
            this.flags = data.flags;
        }
    }

    public dispatch(): ResponseInterface<T> {
        return this;
    }

    public data(): any {
        return this.body;
    }

    public warningMessages(): string[] {
        const messages: string[] = [];

        for (const notification of this.notifications) {
            if (notification.class === 'Warning') {
                messages.push(notification.message);
            }
        }

        return messages;
    }

    public errorMessages(): string[] {
        const messages: string[] = [];

        for (const notification of this.notifications) {
            if (notification.class === 'Error') {
                messages.push(notification.message);
            }
        }

        return messages;
    }

    public successMessages(): string[] {
        const messages: string[] = [];

        for (const notification of this.notifications) {
            if (notification.class === 'Success') {
                messages.push(notification.message);
            }
        }

        return messages;
    }
}

export class DataResponse<T> extends AbstractResponse<T> {

}

export class SelectionResponse<T> extends AbstractResponse<T> {
    constructor(data: any) {
        super(data);
        if (data.body.results) {
            this.body = data.body.results;
        }
    }
}

export class SemanticUIResponse<T> extends AbstractResponse<T> {

}

export class ErrorResponse<T = string> extends AbstractResponse<T> {
    public dispatch(): ResponseInterface<T> {
        console.error(this.body);

        return this;
    }
}

export class ExtGridResponse<T> extends AbstractResponse<T> {
    public total = 0;

    constructor(data: any) {
        super(data);
        if (data.body.data) {
            this.body = data.body.data;
        }

        this.total = data.body.total ? data.body.total : 0;
    }
}

export interface UiSelectionOption {
    value: string;
    name: string;
    text: string;
    disabled: boolean;
}

export const Response = new ResponseFactory();
