import 'promise-polyfill/src/polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from '@/plugins/vuetify';
import VueMask from 'v-mask';
import VueAxios from 'vue-axios';
import api from '@/services/Api';
import moment from 'moment';
import { CreateElement } from 'vue/types/vue';
import { VNode } from 'vue/types/vnode';
import DOMPurify from 'dompurify';

Vue.use(VueAxios, api);
Vue.use(VueMask);

// v-text-field: Icon-Button nicht fokusieren bei Tab-Eingabe
Vue.directive('append-focus', {
    inserted: function(el) {
        const button = el.querySelector('button');
        if (button) {
            button.setAttribute('tabindex', '-1');
        }
    },
});

// v-tooltip + v-btn: Button nicht fokusieren
Vue.directive('blur', {
    inserted: (el) => {
        el.onfocus = (ev: FocusEvent): void => (ev.target as HTMLElement).blur();
    }
});

Vue.config.productionTip = false;

moment.locale('de-at');

Vue.prototype.$d = function(datum?: string | null, format?: string): string | null {
    const d = moment(datum);
    return d.isValid() ? d.format(format ?? 'DD.MM.YYYY') : datum as string | null;
};

Vue.prototype.$dt = function(datum: string | null, format?: string): string {
    const d = moment(datum, ['DD.MM.YYYY HH:mm:ss', 'YYYY-MM-DD HH:mm:ss.SSS']);
    return d.isValid() ? d.format(format ?? 'DD.MM.YYYY - HH:mm') : '-';
};

Vue.prototype.$b = function(betrag: number | string | null): string {
    if (!betrag) betrag = 0;
    return parseFloat(betrag?.toString()).toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

Vue.prototype.$purify = function(html: | string | null,
                                 allowedTags = ['div', 'b', 'i', 'pre', 'br', 'font', 'span', 'u']): string {
    return html ? DOMPurify.sanitize(html, { ALLOWED_TAGS: allowedTags }) : '';
};

new Vue({
    vuetify,
    router,
    store,
    render: (h: CreateElement): VNode => h(App),
}).$mount('#terminbuchung');
