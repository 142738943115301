export default class Anforderung {
    public anforderung_id: number | null = null;
    public bezeichnung: string | null = null;
    public dauer: string | null = null;
    public gueltig: string | null = null;

    constructor(data?: Partial<Anforderung>) {
        Object.assign(this, data);
    }
}
