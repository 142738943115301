import api from '../services/Api';
import { ApplicationState, RootState } from '@/store/types';
import { ActionContext, Module } from 'vuex';
import { Response } from '@/services/Response';
import { workers } from '@/services/Workers';

type Context = ActionContext<ApplicationState, RootState>;

export type Release = {
    revision: number;
    version: string;
}

export const initialState: ApplicationState = {
    version: process.env.VERSION as string,
    prevVersion: process.env.VERSION as string,
    loading: false,
    maintenance: false,
    initialized: false,
    lastRoute: '/',
};

export const application: Module<ApplicationState, RootState> = {
    namespaced: true,
    state: initialState,
    getters: {
        version: (state): string => {
            return state.version;
        },
        initialized: (state): boolean => {
            return state.initialized;
        },
        lastRoute: (state): string => {
            return state.lastRoute;
        },
    },
    actions: {
        startWorker({ dispatch }: Context): void {
            dispatch('checkVersion');

            workers.version = setInterval(() => {
                dispatch('checkVersion');
            }, (process.env.VUE_APP_VERSIONCHECK_TIMEOUT ?? 1) * 1000 * 60);
        },
        stopWorker(): void {
            clearInterval(workers.version);
            workers.version = null;
        },
        checkVersion({ commit, state }: Context): Promise<void> {
            return api.post('app/index')
                .then((data) => {
                    const release = Response.factory<Release | null>(data).dispatch().data();
                    if (release) {
                        const { version } = release;

                        if (version && version !== state.version) {
                            commit('changeVersion', version);
                        }
                    }

                    commit('maintenance', false);
                })
                .catch(console.error);
        },
        rerollVersion({ commit }: Context): void {
            commit('rerollVersion');
        },
    },
    mutations: {
        changeVersion(state: ApplicationState, version: string): void {
            state.prevVersion = state.version;
            state.version = version;
        },
        rerollVersion(state: ApplicationState): void {
            state.version = state.prevVersion;
        },
        loading(state: ApplicationState, loading: boolean): void {
            state.loading = loading;
        },
        maintenance(state: ApplicationState, value: boolean): void {
            state.maintenance = value;
        },
        initialized(state: ApplicationState, value: boolean): void {
            state.initialized = value;
        },
        lastRoute(state: ApplicationState, value: string): void {
            state.lastRoute = value;
        },
    },
};
