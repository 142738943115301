import Vue from 'vue';
import Vuetify, { VTextField } from 'vuetify/lib';
import VCurrencyField from 'v-currency-field';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import de from 'vuetify/src/locale/de';

Vue.use(Vuetify);
Vue.component('VTextField', VTextField);
Vue.use(VCurrencyField, {
    locale: 'de-DE',
    decimalLength: 2,
    autoDecimalMode: false,
    min: null,
    max: null,
    defaultValue: 0,
    valueAsInteger: false,
    allowNegative: true,
});

const locale = 'de-AT';

const vuetify = new Vuetify({
    lang: {
        locales: { [locale]: de },
        current: locale,
    },
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: '#9a258f', // #9b2590
                secondary: '#445051',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FB8C00',
                grey: '#f0f0f0',
            },
        },
    },
});

export default vuetify;
