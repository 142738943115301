import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import store from '@/store/index';
import Vue from 'vue';
import moment from 'moment';

class SessionRefresher {
    protected lastRequest?: AxiosRequestConfig;
    protected sessionRefreshTimeout?: number = undefined;

    public setLastRequest(r?: AxiosRequestConfig): void {
        this.lastRequest = r;
        if (process.env.VUE_APP_SESSION_REFRESH_TIMEOUT) {
            if (this.sessionRefreshTimeout) {
                clearTimeout(this.sessionRefreshTimeout);
            }
            this.sessionRefreshTimeout = setTimeout(() => {
                const url = process.env.VUE_APP_SESSION_REFRESH_URL
                    ? process.env.VUE_APP_SESSION_REFRESH_URL
                    : 'authentication/index/sessionrefresh';
                Vue.axios.post(url);
            }, (process.env.VUE_APP_SESSION_REFRESH_TIMEOUT * 1000 * 60));
        }
    }
}

const refresher = new SessionRefresher();
refresher.setLastRequest();

export const base = process.env.VUE_APP_API_BASE_URL || '';

class Api {
    constructor(config?: AxiosRequestConfig) {
        if (!config) {
            config = {
                headers: {
                    'Cache-Control': 'no-cache',
                    CacheControl: 'no-cache',
                    Pragma: 'no-cache',
                },
            };
        }
        const instance = axios.create(config);
        instance.defaults.baseURL = base;
        instance.defaults.withCredentials = true;
        instance.interceptors.request.use((request) => {
            if (request.method === 'post' || request.method === 'get') {
                if (request.url && /\?/.test(request.url)) {
                    request.url = request.url + '&_dc=' + Math.random() + moment().unix();
                } else if (request.url) {
                    request.url = request.url + '?_dc=' + Math.random() + moment().unix();
                }
            }
            refresher.setLastRequest(request);
            return request;
        });

        instance.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (error && error.response) {
                if (error.response.status === 401) {
                    store.dispatch('authentication/unauthorized');
                    return Promise.reject(error);
                } else {
                    if (error.response.status === 500 && process.env.VUE_APP_SHOW_NETWORK_ERRORS === '1') {
                        console.error('Network Error', error.response.data.body);
                    }
                    return Promise.reject(error);
                }
            } else {
                // TODO: Network Error - throw general Error?
                console.error(error);
                return Promise.reject(error);
            }
        });

        return instance;
    }
}

const api = (new Api() as AxiosInstance);
export default api;
